<template>
  <div>
    <van-tabs v-model="akId"
      @click="onClick"
      style="border: 10px solid rgb(247, 247, 247);">
      <van-tab title="党史学习"
        name="1">
        <div class="information">
          <!-- 全部 -->
          <div class="center"
            v-for="item in lists"
            :key="item.index">
            <router-link :to="'/partymember/history/activity/' + item.AId">
              <!-- <div class="centerTitle van-ellipsis">{{ item.Title }}</div> -->
              <div class="Content">
                <div style="display: inline-block;width: 67%;"
                  class="ContentB">
                  <div class="centerTitle van-ellipsis--l2">{{ item.Title }}</div>
                </div>
                <div class="ContentA"
                  ref="getHeight">
                  <img v-if="item.AImgUrl"
                    style="border-radius:5px;"
                    :src="item.AImgUrl"
                    alt="" />
                </div>
                <span class="centertime"
                  style="float: left;margin-top: -10px;color:#999999;">{{ item.ADate }}</span>
              </div>
            </router-link>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden"
            class="hidden">───── 没有更多数据了 ─────</div>
        </div>
      </van-tab>
      <van-tab title="我为群众办实事"
        name="2">
        <div class="information">
          <!-- 全部 -->
          <div class="center"
            v-for="item in list"
            :key="item.index">
            <router-link :to="'/partymember/history/' + item.RIId">
              <!-- <div class="centerTitle van-ellipsis">{{ item.Title }}</div> -->
              <div class="Content">
                <div style="display: inline-block;width: 67%;"
                  class="ContentB">
                  <div class="centerTitle van-ellipsis--l2">{{ item.Title }}</div>
                </div>
                <div class="ContentA"
                  ref="getHeight">
                  <img v-if="item.AImgUrl"
                    style="border-radius:5px;"
                    :src="item.AImgUrl"
                    alt="" />
                </div>
                <span class="centertime"
                  style="float: left;margin-top: -10px;color:#999999;">{{ item.ADate }}</span>
              </div>
            </router-link>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden"
            class="hidden">───── 没有更多数据了 ─────</div>
        </div>
      </van-tab>
      <van-tab title="党建大事件"
        name="3">
        <van-steps direction="vertical"
          active-color="red"
          inactive-color="red"
          active="99999">
          <van-step v-for="item in Steps"
            :key="item">
            <h3>{{item.Time}}</h3>
            <p>{{item.Title}}</p>
            <p style="color:#333">{{item.Content}}</p>
          </van-step>
        </van-steps>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WeGetPartyInfoPage, WeGetMyActivityPage, WxGetDateAxisList } from "@/api/RealInfo";
export default {
  data () {
    return {
      akId: 2, //tab栏
      list: [], //办实事列表
      lists: [], //党史学习列表
      Steps: [], //党建大事件
      listfrom: {}, //查询传参
      hidden: true, //没有更多数据了
    };
  },
  methods: {
    // 获取分页列表
    getList () {
      WeGetPartyInfoPage({ riKind: 5 })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].ThematicUrl == "") {
                  this.list[i].ThematicUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => { });
    },
    getLists () {
      WeGetMyActivityPage({ akId: -1 })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.lists = {};
            } else {
              this.lists = res.data.data;
              console.log(this.lists);
              for (var i = 0; i < this.lists.length; i++) {
                if (this.lists[i].AImgUrl == "") {
                  this.lists[i].AImgUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => { });
    },
    AxisList () {
      WxGetDateAxisList().then((res) => {
        this.Steps = res.data.data;
      })
    },
    // tan点击切换事件
    onClick (e) {
      this.akId = e;
      this.getList();
    },
  },
  mounted () {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getList();
    this.getLists();
    this.AxisList();
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>
<style scoped>
.van-ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>